import React from "react";
import { Link } from "react-router-dom";


const WebNotFound = ({ props }) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <h1>Oopppsss.. esta página no existe</h1>
        <Link to="/">Regresar al inicio</Link>
      </div>
    </div>
  );
};

export default WebNotFound;
