import React, { useContext, useEffect, useState } from "react";
import { Col, Input, Message, Notification, Row, Schema, toaster, } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import ButtonAccent from "../../components/ButtonAccent";
import ErrorNotification from "../../components/Error";
import { CaptionText, Title1, } from "../../components/TextComponents";
import EspecialistasAPIClass from "../../services/especialistas";
import PacientesAPIClass from "../../services/pacientes";
import AuthAPIClass from "../../services/auth";
import UserState from "../../state/UserState";
import ButtonLink from "../../components/ButtonLink";
import { useNavigate } from "react-router";
import colors from "../../constants/colors";
import { VerificationPin, StatusType } from "react-verification-pin";

const EspecialistasAPI = new EspecialistasAPIClass();
const pacientesAPI = new PacientesAPIClass();
const AuthAPI = new AuthAPIClass();

const Login = ({ props }) => {
  const history = useNavigate();
  const [loader, setLoader] = useStateIfMounted(false);
  const [loaderVerificacion, setLoaderVerificacion] = useStateIfMounted(false);
  const { setUser, setUserPrincipal, setOrganizacion } = useContext(UserState);
  const [error, setError] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("form");
  const [codigoVerificacion, setCodigoVerificacion] = useStateIfMounted("");
  const [statusVer, setStatusVer] = useState("process");

  const { StringType, NumberType, ObjectType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [username, setUsername] = useStateIfMounted("");
  const [usernameError, setUsernameError] = useStateIfMounted(false);
  const usernameModel = Schema.Model({
    username: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateusername();
  }, [username]);
  const _validateusername = () => {
    let errors = usernameModel.check({
      username: username,
    });
    if (errors.username.hasError) {
      let errorInfo = {
        hasError: errors.username.hasError,
        error: errors.username.hasError ? errors.username.errorMessage : "",
      };
      setUsernameError(errorInfo);
    } else {
      setUsernameError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [password, setpassword] = useStateIfMounted("");
  const [passwordError, setpasswordError] = useStateIfMounted(false);
  const passwordModel = Schema.Model({
    password: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatepassword();
  }, [password]);
  const _validatepassword = () => {
    let errors = passwordModel.check({
      password: password,
    });
    if (errors.password.hasError) {
      let errorInfo = {
        hasError: errors.password.hasError,
        error: errors.password.hasError ? errors.password.errorMessage : "",
      };
      setpasswordError(errorInfo);
    } else {
      setpasswordError(false);
    }
  };
  // **********************************************************

  const _validateForm = () => {
    if (!usernameError && !passwordError && username != "" && password != "") {
      return _login();
    }
    setError("Verifica la validación del formulario");

    _validateusername();
    _validatepassword();
  };

  const _verificarCodigo = async (code) => {
    if (code !== "") {
      setStatusVer("wait");
      setLoaderVerificacion(true);
      setError(false);
      let verificacionLS = localStorage.getItem("verificacion");
      const { data } = await EspecialistasAPI.VerificarCodigo(
        verificacionLS,
        code
      );
      // console.log(data);
      if (data !== false) {
        setStatusVer("success");
        if (data.data_user_type === "especialista") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
              {"Hola especialista"}
            </Message>,
            { placement: "topEnd" }
          );

          localStorage.setItem("uid_user", data.uid);
          localStorage.setItem("tipo_user", "especialista");
          localStorage.removeItem("verificacion");
          setUser(data);
          setUserPrincipal(data);
          setOrganizacion(data.organizacion);
          window.location.href = '/especialista';
        } else if (data.data_user_type === "paciente") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
              {"Hola " + data.data.nombre}
            </Message>,
            { placement: "topEnd" }
          );
          localStorage.setItem("uid_user", data.data.uid);
          localStorage.setItem("tipo_user", "paciente");
          localStorage.removeItem("verificacion");
          setUser(data.data);
          setUserPrincipal(data.data);
          window.location.href = '/paciente';
        } else if (data.data_user_type === "asistente") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Hecho!" closable duration={4500}>
              {"Hola " + data.nombre}
            </Message>,
            { placement: "topEnd" }
          );
          localStorage.setItem("uid_user", data.uid);
          localStorage.setItem("tipo_user", "asistente");
          localStorage.removeItem("verificacion");
          setUser(data);
          setUserPrincipal(data);
          window.location.href = '/especialista';
        }
      } else {
        setStatusVer("error");
        setError("El código no es correcto, intenta nuevamente");
      }

      setLoaderVerificacion(false);
    } else {
      setError("Ingresa el código de verificación");
    }
  };

  const _login = async () => {
    try {
      setLoader(true);
      setError(false);
      const request = {
        username,
        password,
      };
      const { data } = await AuthAPI.login(request);
      // console.log(data);
      if (data.error) {
        setError(data.errorText);
        setLoader(false);
      } else {
        if (data.perfil.data_user_type === "especialista") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
              {"Hola especialista"}
            </Message>,
            { placement: "topEnd" }
          );
          localStorage.setItem("uid_user", data.perfil.uid);
          localStorage.setItem("tipo_user", "especialista");
          localStorage.removeItem("verificacion");
          setUser(data.perfil);
          setUserPrincipal(data.perfil);
          setOrganizacion(data.perfil.organizacion);
          window.location.href = '/especialista';
        } else if (data.perfil.data_user_type === "paciente") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
              {"Hola " + data.perfil.data.nombre}
            </Message>,
            { placement: "topEnd" }
          );
          localStorage.setItem("uid_user", data.perfil.data.uid);
          localStorage.setItem("tipo_user", "paciente");
          localStorage.removeItem("verificacion");
          setUser(data.perfil.data);
          setUserPrincipal(data.perfil.data);
          window.location.href = '/paciente';
        } else if (data.perfil.data_user_type === "asistente") {
          toaster.push(
            <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
              {"Hola " + data.nombre}
            </Message>,
            { placement: "topEnd" }
          );
          localStorage.setItem("uid_user", data.perfil.uid);
          localStorage.setItem("tipo_user", "asistente");
          localStorage.removeItem("verificacion");
          setUser(data.perfil);
          setUserPrincipal(data.perfil);
          window.location.href = '/especialista';
        }
      }
    } catch (error) {
      setError("Ocurrió un error desconocido, por favor intenta mas tarde");
      setLoader(false);
    }
  };

  return (
    <div>
      {/* ====== FORM ====== */}
      {section === "form" ? (
        <div
          className="animate__animated animate__fadeIn"
          style={{ width: "100%" }}
        >
          <div className="flex-jb-ac">
            <Title1 bold text="Iniciar sesión"></Title1>
            <ButtonLink text="Cancelar" onClickFunction={() => (window.location.href = "https://docca.co")} color={colors.textSecondary}></ButtonLink>
          </div>

          <div style={{ marginTop: 20 }}>
            <Row>
              <Col xs={24}>
                <div style={{ marginBottom: 10 }}>
                  <CaptionText color="#7e8084" marginBottom={5} text="Usuario"></CaptionText>
                  <Input
                    placeholder=""
                    value={username}
                    onChange={(val) => setUsername(val)}
                    className={usernameError ? "input-error" : "input-no-error"}
                    placeHolder={usernameError ? usernameError.error : ""}
                  ></Input>
                  {/* {usernameError && (
                    <ErrorNotification text={usernameError.error} />
                  )} */}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <div style={{ marginBottom: 10 }}>
                  <CaptionText color="#7e8084" marginBottom={5} text="Contraseña"></CaptionText>
                  <Input
                    value={password || ''} name={'password'}
                    onChange={(val) => setpassword(val)}
                    type="password"
                    placeholder=""
                    className={passwordError ? "input-error" : "input-no-error"}
                    placeHolder={passwordError ? passwordError.error : ""}
                  ></Input>
                  {/* {passwordError && (
                    <ErrorNotification text={passwordError.error} />
                  )} */}
                </div>
              </Col>
            </Row>

            {error !== false && (
              <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20, }}>
                <Message showIcon type="error" style={{ width: "100%" }} closable ><p>{error}</p></Message>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: 20, }}>
              <ButtonLink onClickFunction={() => history("/password-recovery")} text="Olvidaste tu contraseña?"></ButtonLink>
            </div>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20, }}>
              <Col xs={24} md={12}>
                <ButtonAccent onClick={() => _validateForm()} text="Iniciar Sesión" loader={loader ? true : false} block></ButtonAccent>
              </Col>
            </div>
          </div>
        </div>
      ) : (
        <div className="animate__animated animate__fadeIn" style={{ width: "100%" }}>
          {/* <div className="flex-jb-ac">
            <Title1 bold text={`Confirmación`}></Title1>
          </div>
          <p
            style={{ fontSize: 16 }}
          >{`Ingresa el código de verificación que enviamos a tu teléfono.`}</p> */}
          <div style={{ marginTop: 20 }}>
            <div style={{ marginBottom: 10 }}>
              <VerificationPin
                type="number"
                inputsNumber={6}
                onChange={(val) => setCodigoVerificacion(val)}
                status={statusVer}
                title="Confirma tu número de telefono"
                subTitle="Recibirás un mensaje de texto con el código de verificación"
                onFinish={_verificarCodigo}
              />
              {/* <CaptionText
                color="#7e8084"
                marginBottom={5}
                text="Ingresa el código"
              ></CaptionText> */}
              {/* <Input
                value={codigoVerificacion}
                onChange={(val) => setCodigoVerificacion(val)}
                type="text"
                placeholder="######"
              ></Input> */}
            </div>

            {error !== false && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Message
                  showIcon
                  type="error"
                  description={<p>{error}</p>}
                  style={{ width: "100%" }}
                  closable
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: 20,
              }}
            >
              <Col xs={24} md={12}>
                <ButtonAccent
                  block
                  onClick={() => _verificarCodigo()}
                  text="Verificar"
                  loader={loaderVerificacion ? true : false}
                ></ButtonAccent>
              </Col>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
