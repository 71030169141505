import React, { useState } from "react";
import { Button, Row, Col, Grid, Modal } from "rsuite";
import TABLET from "../../../assets/images/tablet.png";
import DOCTOR from "../../../assets/images/doctorLanding.png";
import SVGHERO from "../../../assets/images/hero-shape-svg.svg";
import Login from "../../login";
import { useNavigate } from "react-router-dom";
import colors from "../../../constants/colors";
import { ReactComponent as ReactLogo } from "../../../assets/images/docca_logo.svg";

const LandingPage = () => {
  const history = useNavigate();
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className="landing" style={{ display: "flex", }}>
        <Modal open={modal} onClose={() => setModal(false)}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="login__modal">
              <Login></Login>
            </div>
          </Modal.Body>
        </Modal>
        <div style={{ flex: 1, paddingTop: 15, display: "flex", flexDirection: "column" }}>
          <div className="landing__menu__logo" style={{ width: "100px", marginLeft: 20 }}>
            <ReactLogo fill={colors.branding} stroke={colors.branding}></ReactLogo>
            {/* <img src={LOGO}></img> */}
          </div>
          <div style={{ marginLeft: 100, flex: 1, display: "flex", justifyContent: "center", flexDirection: "column", maxWidth: 480 }} className="lading__hero__caption">
            <h1 style={{marginTop:80, fontSize: 60, color: colors.branding }}>Bienvenido!</h1>
            <p style={{ fontWeight: 400, fontSize: 24, color: colors.textSecondaryDarken, maxWidth: 380 }}>
              Gracias por ser parte de
            </p>
            <p style={{ fontWeight: 400, fontSize: 24, color: colors.textSecondaryDarken, maxWidth: 400, marginTop: 0, lineHeight: 1 }}>
              la plataforma médica del futuro.
            </p>
            <p style={{ fontSize: 15 }}>
              Crea tu cuenta o inicia sesión para comenzar a utilizar <span style={{ fontWeight: "bold" }}>docca.</span>
            </p>
            <div style={{ display: "flex", marginTop: 10, gap: 10 }}>
              <Button
                size="md"
                style={{maxWidth:180, borderRadius: 20, width: "100%", backgroundColor: colors.branding, color: "#fff" }}
                onClick={() => history("/registro")}
              // className="button-cta"
              >
                Crear cuenta
              </Button>
              <Button
                size="md"
                style={{maxWidth:180, borderRadius: 20, width: "100%", backgroundColor: colors.branding, color: "#fff" }}
                onClick={() => setModal(true)}
              // className="button-cta"
              >
                Iniciar sesión
              </Button>
            </div>

          </div>
        </div>
        <div style={{ flex: 1, justifyContent: "flex-end" }} className="landing__hero">
          <div className="landing__hero__wrap" style={{ marginRight: 20 }}>
            <div className="lading__hero__image">
              <img src={DOCTOR}></img>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="landing"> */}
      {/* ===== MODAL ===== */}

      {/* ===== /MODAL ===== */}

      {/* ===== MENU ===== */}
      {/* <div className="landing__menu" style={{ paddingTop: 8 }}> */}

      {/* <div className="landing__menu__links">
          <div className="landing__menu__links__item">Acerca de Docca</div>
          <div className="landing__menu__links__item">Aviso de privacidad</div>
          <div className="landing__menu__links__item">Contacto</div>
          <Button size="sm" style={{ backgroundColor: colors.branding, color: "#fff" }} className="buttonBranding" onClick={() => setModal(true)}>
            INICIAR SESIÓN
          </Button>
        </div> */}
      {/* </div> */}
      {/* ===== /MENU ===== */}

      {/* ===== HERO ===== */}
      {/* <div className="landing__hero">
          <div className="landing__hero__wrap">
            <Grid>
              <Row>
                <Col xs={24} md={10}>

                </Col>
                <Col xs={24} md={14} style={{ display: "flex", justifyContent: "flex-end" }}>

                </Col>
              </Row>
            </Grid>
          </div>
        </div> */}
      {/* ===== /HERO ===== */}
      {/* </div> */}
    </>

  );
};

export default LandingPage;
