import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Col,
  Grid,
  Input,
  Message,
  Notification,
  Row,
  Schema,
  toaster,
} from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import ButtonAccent from "../../components/ButtonAccent";
import ErrorNotification from "../../components/Error";
import {
  CaptionText,
  Title1,
} from "../../components/TextComponents";

import EspecialistasAPIClass from "../../services/especialistas";
import UserState from "../../state/UserState";
import BG from "../../assets/images/bg-login.jpg";
import LOGO from "../../assets/images/logo_white.png";

import ButtonLink from "../../components/ButtonLink";
import { ReCaptcha } from "../../components/Recaptcha";
import { useNavigate } from "react-router";
import colors from "../../constants/colors";
import AuthAPIClass from "../../services/auth";
import PacienteAPIClass from "../../services/pacientes";

const EspecialistasAPI = new EspecialistasAPIClass();
const AuthAPI = new AuthAPIClass();
const PACIENTEAPI = new PacienteAPIClass();

const PasswordRecovery = ({ props }) => {
  const history = useNavigate();
  // const [tokencaptcha, setTokencaptcha] = useState('');

  const [loader, setLoader] = useStateIfMounted(false);
  const [loaderVerificacion, setLoaderVerificacion] = useStateIfMounted(false);
  const [loaderPassword, setLoaderPassword] = useStateIfMounted(false);

  const { setUser, setUserPrincipal } = useContext(UserState);
  const [error, setError] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("form");
  const [codigoVerificacion, setCodigoVerificacion] = useStateIfMounted("");

  const { StringType, NumberType, ObjectType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [email, setemail] = useStateIfMounted("");
  const [emailError, setemailError] = useStateIfMounted(false);
  const emailModel = Schema.Model({
    email: StringType()
      .isEmail("Ingresa un email válido")
      .isRequired("Este campo es obligatorio"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateemail();
  }, [email]);

  // const _validateCaptcha = () => {
  //   GoogleReCaptcha.execute();
  // }

  const _validateemail = () => {
    let errors = emailModel.check({
      email: email,
    });
    if (errors.email.hasError) {
      let errorInfo = {
        hasError: errors.email.hasError,
        error: errors.email.hasError ? errors.email.errorMessage : "",
      };
      setemailError(errorInfo);
    } else {
      setemailError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [password, setpassword] = useStateIfMounted("");
  const [passwordError, setpasswordError] = useStateIfMounted(false);
  const passwordModel = Schema.Model({
    password: StringType()
      .isRequired("Campo Requerido")
      .minLength(8, "La contraseña debe contener al menos 8 caracteres"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatepassword();
  }, [password]);
  const _validatepassword = () => {
    let errors = passwordModel.check({
      password: password,
    });
    if (errors.password.hasError) {
      let errorInfo = {
        hasError: errors.password.hasError,
        error: errors.password.hasError ? errors.password.errorMessage : "",
      };
      setpasswordError(errorInfo);
    } else {
      setpasswordError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [repassword, setrepassword] = useStateIfMounted("");
  const [repasswordError, setrepasswordError] = useStateIfMounted(false);
  const repasswordModel = Schema.Model({
    repassword: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validaterepassword();
  }, [repassword]);
  const _validaterepassword = () => {
    let errors = repasswordModel.check({
      repassword: repassword,
    });
    if (errors.repassword.hasError) {
      let errorInfo = {
        hasError: errors.repassword.hasError,
        error: errors.repassword.hasError ? errors.repassword.errorMessage : "",
      };
      setrepasswordError(errorInfo);
    } else {
      setrepasswordError(false);
    }
  };
  // **********************************************************

  const _validateFormEmail = () => {
    if (!emailError && localStorage.getItem("emailProvisional") !== null) {
      return _changePasswordRequest();
    }
    setError("Verifica la validación del formulario");

    _validateemail();
  };

  const _validateFormPassword = () => {
    if (
      !passwordError &&
      !repasswordError &&
      password !== "" &&
      repassword !== ""
    ) {
      if (password !== repassword) {
        setError("Las contraseñas no coinciden");
        return null;
      } else {
        return _changePassword();
      }
    }
    setError("Verifica la validación del formulario");

    _validatepassword();
    _validaterepassword();
  };

  const _changePasswordRequest = async () => {
    setLoader(true);
    setError(false);

    const { data } = await AuthAPI.ChangePasswordRequest(
      localStorage.getItem("emailProvisional")
    );

    if (data.error === true) {
      setError(data.errorText);
    }

    if (data.error === false) {
      localStorage.setItem("verificacionChangePassword", data.verificacion);
      localStorage.setItem("uidChangePassword", data.uid);
      localStorage.setItem("tipo", data.tipo);
      localStorage.removeItem("emailProvisional");

      setSection("confirmacion");
    }

    setLoader(false);
  };

  const _verificarCodigo = async () => {
    if (codigoVerificacion !== "") {
      setLoaderVerificacion(true);
      setError(false);
      let verificacionLS = localStorage.getItem("verificacionChangePassword");

      const { data } = await EspecialistasAPI.VerificarCodigoChangePassword(
        verificacionLS,
        codigoVerificacion
      );

      if (data === true) {
        setSection("password");
      } else {
        setError("El código no es correcto, intenta nuevamente");
      }

      setLoaderVerificacion(false);
    } else {
      setError("Ingresa el código de verificación");
    }
  };

  const _changePassword = async () => {
    setLoaderPassword(true);
    setError(false);

    if (localStorage.getItem("tipo") === 1) {
      let request = {
        uid: localStorage.getItem("uidChangePassword"),
        password: password,
      };

      const { data } = await EspecialistasAPI.ChangePassword(request);

      if (data !== false) {
        toaster.push(
          <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
            {"Hola especialista"}
          </Message>,
          { placement: "topEnd" }
        );
        localStorage.setItem("uid_user", data.uid);
        localStorage.setItem("tipo_user", "especialista");
        localStorage.removeItem("verificacionChangePassword");
        localStorage.removeItem("uidChangePassword");
        localStorage.removeItem("tipo");

        setUser(data);
        setUserPrincipal(data);
      } else {
        setError(
          "La contraseña se actualizó pero tuvimos un error al intentar redireccionarte, inicia sesión con tu nueva contraseña"
        );
      }

      setLoaderPassword(false);
    } else {
      let request = {
        uid: localStorage.getItem("uidChangePassword"),
        password: password,
      };

      const { data } = await PACIENTEAPI.ChangePassword(request);

      if (data !== false) {
        localStorage.setItem("uid_user", data.uid);
        localStorage.setItem("tipo_user", "paciente");
        localStorage.removeItem("verificacionChangePassword");
        localStorage.removeItem("uidChangePassword");
        localStorage.removeItem("tipo");

        setUser(data.data);
        setUserPrincipal(data.data);
      } else {
        setError(
          "La contraseña se actualizó pero tuvimos un error al intentar redireccionarte, inicia sesión con tu nueva contraseña"
        );
      }

      setLoaderPassword(false);
    }
  };
  const _pruebaverify = () => { };

  const _getCaptchaScore = async (token) => {
    let request = {
      recaptcha: token,
    };
    const { data } = await EspecialistasAPI.GetCaptchaScore(request);

    if (data.success === true && data.score >= 0.5) {
      _validateFormEmail();
    }
  };
  const handleReCaptchaVerify = (token) => {
    _getCaptchaScore(token);
  };

  const _setemail = (val) => {
    localStorage.setItem("emailProvisional", val);
    setemail(val);
  };

  return (
    <div>
      <Grid fluid>
        <Row>
          <Col xs={24} md={12} className="auth__bg">
            <div
              className="auth__bg__content animate__animated animate__fadeIn"
              style={{
                backgroundImage: `url(${BG})`,
              }}
            >
              <div className="auth__bg__content__wrap animate__animated animate__fadeIn animate__delay-1s">
                <img src={LOGO} />
                <h3>Administración médica de vanguardia</h3>
                <p>Bienvenido de nuevo!</p>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            md={12}
            className="auth__form__wrap"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* ====== FORM ====== */}
            {section === "form" && (
              <div
                className="animate__animated animate__fadeIn"
                style={{ width: "100%" }}
              >
                <div className="flex-jb-ac">
                  <Title1 bold text="Recuperar contraseña"></Title1>
                  <ButtonLink
                    text="Cancelar"
                    onClickFunction={() => history("/")}
                    color={colors.textSecondary}
                  ></ButtonLink>
                </div>

                <div style={{ marginTop: 20 }}>
                  <Row>
                    <Col xs={24}>
                      <div style={{ marginBottom: 10 }}>
                        <CaptionText
                          color="#7e8084"
                          marginBottom={5}
                          text="Ingresa el correo de tu cuenta"
                        ></CaptionText>
                        <Input
                          placeholder="@..."
                          value={email || ''} name={'email'}
                          onChange={(val) => _setemail(val)}
                          className={
                            emailError ? "input-error" : "input-no-error"
                          }
                        ></Input>
                        {emailError && (
                          <ErrorNotification text={emailError.error} />
                        )}
                      </div>
                    </Col>
                  </Row>

                  {error !== false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      <Message
                        showIcon
                        type="error"
                        description={<p>{error}</p>}
                        style={{ width: "100%" }}
                        closable
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <Col xs={24} md={12}>
                      <ReCaptcha
                        onVerifyCaptcha={handleReCaptchaVerify}
                        loader={loader}
                      ></ReCaptcha>
                      {/* <ButtonAccent
                          onClick={() => _validateFormEmail()}
                          // onClick={() => handleReCaptchaVerify()}
                          text="Enviar"
                          loader={loader ? true : false}
                          block
                        ></ButtonAccent> */}
                    </Col>
                  </div>
                </div>
              </div>
            )}

            {/* ===== CONFIRMACIÓN ===== */}
            {section === "confirmacion" && (
              <div
                className="animate__animated animate__fadeIn"
                style={{ width: "100%" }}
              >
                <div className="flex-jb-ac">
                  <Title1 bold text={`Confirmación`}></Title1>
                </div>
                <p
                  style={{ fontSize: 16 }}
                >{`Ingresa el código de verificación que enviamos a tu teléfono.`}</p>
                <div style={{ marginTop: 20 }}>
                  <div style={{ marginBottom: 10 }}>
                    <CaptionText
                      color="#7e8084"
                      marginBottom={5}
                      text="Ingresa el código"
                    ></CaptionText>
                    <Input
                      value={codigoVerificacion}
                      onChange={(val) => setCodigoVerificacion(val)}
                      type="text"
                      placeholder="######"
                    ></Input>
                  </div>

                  {error !== false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      <Message
                        showIcon
                        type="error"
                        description={<p>{error}</p>}
                        style={{ width: "100%" }}
                        closable
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <Col xs={24} md={12}>
                      <ButtonAccent
                        block
                        onClick={() => _verificarCodigo()}
                        text="Verificar"
                        loader={loaderVerificacion ? true : false}
                      ></ButtonAccent>
                    </Col>
                  </div>
                </div>
              </div>
            )}

            {/* ===== CAMBIAR PASSWORD ===== */}
            {section === "password" && (
              <div
                className="animate__animated animate__fadeIn"
                style={{ width: "100%" }}
              >
                <div className="flex-jb-ac">
                  <Title1 bold text={`Nueva contraseña`}></Title1>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Row>
                    <Col xs={12}>
                      <div style={{ marginBottom: 10 }}>
                        <CaptionText
                          color="#7e8084"
                          marginBottom={5}
                          text="Contraseña"
                        ></CaptionText>
                        <Input
                          value={password || ''} name={'password'}
                          onChange={(val) => setpassword(val)}
                          type="password"
                          placeholder=""
                          className={
                            passwordError ? "input-error" : "input-no-error"
                          }
                        ></Input>
                        {passwordError && (
                          <ErrorNotification text={passwordError.error} />
                        )}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div style={{ marginBottom: 10 }}>
                        <CaptionText
                          color="#7e8084"
                          marginBottom={5}
                          text="Confirmar contraseña"
                        ></CaptionText>
                        <Input
                          value={repassword || ''} name={'repassword'}
                          onChange={(val) => setrepassword(val)}
                          type="password"
                          placeholder=""
                          className={
                            repasswordError ? "input-error" : "input-no-error"
                          }
                        ></Input>
                        {repasswordError && (
                          <ErrorNotification text={repasswordError.error} />
                        )}
                      </div>
                    </Col>
                  </Row>

                  {error !== false && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      <Message
                        showIcon
                        type="error"
                        description={<p>{error}</p>}
                        style={{ width: "100%" }}
                        closable
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <Col xs={24} md={12}>
                      <ButtonAccent
                        block
                        onClick={() => _validateFormPassword()}
                        text="Verificar"
                        loader={loaderPassword ? true : false}
                      ></ButtonAccent>
                    </Col>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PasswordRecovery;
