import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  Grid,
  Input,
  InputPicker,
  Message,
  Row,
  Schema,
  Button,
  Modal,
  toaster,
  Breadcrumb,
  Whisper,
  Popover,
  InputGroup,
} from "rsuite";

import { useStateIfMounted } from "use-state-if-mounted";
import { ReactComponent as ReactLogo } from "../../assets/images/docca_logo.svg";

import ButtonAccent from "../../components/ButtonAccent";
import { SubHeadLine, } from "../../components/TextComponents";
import es from 'react-phone-input-2/lang/es.json'
import UserState from "../../state/UserState";
import ControlLabelCustom from "../../components/ControlLabel";
import { useNavigate } from "react-router";
import "../../assets/scss/components/register-especialista.scss";
import moment from "moment";
import EspecialistasAPIClass from "../../services/especialistas";
import PacietnesAPIClass from "../../services/pacientes";
import { IoCalendarOutline } from "react-icons/io5";
import colors from "../../constants/colors";
import Login from "../login";
import { DayPicker } from "react-day-picker";
import esDate from 'date-fns/locale/es';
import PhoneInput from "react-phone-input-2";

const PacientesAPI = new PacietnesAPIClass();
const EspecialistasAPI = new EspecialistasAPIClass();

const Registro = () => {
  const history = useNavigate();
  const { userPrincipal, setUserPrincipal, setUser, _getPacienteData } =
    useContext(UserState);
  const [activeStep, setActiveStep] = useStateIfMounted(0);
  const [idDoctor, setIdDoctor] = useStateIfMounted(0);
  const [idUsuario, setIdUsuario] = useStateIfMounted(0);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted(false);
  const { StringType, NumberType, ObjectType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  //! la seccion inicial
  const [section, setSection] = useStateIfMounted("step1");
  const [subSection, setSubSection] = useStateIfMounted("info");
  //! la seccion inicial
  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);
  const [phoneCode, setPhoneCode] = useStateIfMounted("");
  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************
  // **********************************************************
  const [usuario, setusuario] = useStateIfMounted("");
  const [usuarioError, setusuarioError] = useStateIfMounted(false);
  const usuarioModel = Schema.Model({
    usuario: StringType()
      .isRequired("Campo Requerido")
      .minLength(6, "El usuario debe contener al menos 6 caracteres")
      .maxLength(20, "El usuario debe contener máximo 20 caracteres")
      .addRule((value, data) => {
        var strongRegex = new RegExp("^(?=.*[ñÑ¿()/!@#$%^&*?<>_ ])");
        return !strongRegex.test(value);
      }, "El usuario no debe incluir caracteres especiales ni espacios."),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateusuario();
  }, [usuario]);
  const _validateusuario = () => {
    let errors = usuarioModel.check({
      usuario: usuario,
    });
    if (errors.usuario.hasError) {
      let errorInfo = {
        hasError: errors.usuario.hasError,
        error: errors.usuario.hasError ? errors.usuario.errorMessage : "",
      };
      setusuarioError(errorInfo);
    } else {
      setusuarioError(false);
    }
  };
  // **********************************************************
  // **********************************************************
  const [apellidoPaterno, setapellidoPaterno] = useStateIfMounted("");
  const [apellidoPaternoError, setapellidoPaternoError] =
    useStateIfMounted(false);
  const apellidoPaternoModel = Schema.Model({
    apellidoPaterno: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateapellidoPaterno();
  }, [apellidoPaterno]);
  const _validateapellidoPaterno = () => {
    let errors = apellidoPaternoModel.check({
      apellidoPaterno: apellidoPaterno,
    });
    if (errors.apellidoPaterno.hasError) {
      let errorInfo = {
        hasError: errors.apellidoPaterno.hasError,
        error: errors.apellidoPaterno.hasError
          ? errors.apellidoPaterno.errorMessage
          : "",
      };
      setapellidoPaternoError(errorInfo);
    } else {
      setapellidoPaternoError(false);
    }
  };
  // *********************************************************
  const [generosData, setGenerosData] = useStateIfMounted([]);
  // *********************************************************
  const [nacionalidadData, setNacionalidadData] = useStateIfMounted([]);
  const [nacionalidad, setNacionalidad] = useStateIfMounted();
  const [nacionalidadError, setnacionalidadError] = useStateIfMounted(false);
  const nacionalidadModel = Schema.Model({
    nacionalidad: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenacionalidad();
  }, [nacionalidad]);

  const _validatenacionalidad = () => {
    let errors = nacionalidadModel.check({
      nacionalidad: nacionalidad,
    });
    if (errors.nacionalidad.hasError) {
      let errorInfo = {
        hasError: errors.nacionalidad.hasError,
        error: errors.nacionalidad.hasError ? errors.nacionalidad.errorMessage : "",
      };
      setnacionalidadError(errorInfo);
    } else {
      setnacionalidadError(false);
    }
  };

  // *********************************************************
  const [genero, setGenero] = useStateIfMounted("");
  const [generoError, setgeneroError] = useStateIfMounted(false);
  const generoModel = Schema.Model({
    genero: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validategenero();
  }, [genero]);

  const _validategenero = () => {
    let errors = generoModel.check({
      genero: genero,
    });
    if (errors.genero.hasError) {
      let errorInfo = {
        hasError: errors.genero.hasError,
        error: errors.genero.hasError ? errors.genero.errorMessage : "",
      };
      setgeneroError(errorInfo);
    } else {
      setgeneroError(false);
    }
  };
  // *********************************************************
  const _getGeneros = async () => {
    const { data } = await PacientesAPI.GetCatalogoGeneros();
    let items = [];
    data.map((item) => {
      items.push({
        label: item.nombre,
        value: item.id,
      });
    });
    setGenerosData(items);
  };

  const _getNacionalidades = async () => {
    const { data } = await EspecialistasAPI.GetCatalogoNacionalidades();

    let items = [];
    data.map((item) => {
      items.push({
        label: item.pais,
        value: item.pais,
      });
    });
    setNacionalidadData(items);
  };

  useEffect(() => {
    _getGeneros();
    _getNacionalidades();
  }, []);
  // **********************************************************
  const [fechaNacimiento, setfechaNacimiento] = useStateIfMounted(new Date());
  const [fechaNacimientoError, setfechaNacimientoError] = useStateIfMounted(false);
  const fechaNacimientoModel = Schema.Model({
    fechaNacimiento: ObjectType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatefechaNacimiento();
  }, [fechaNacimiento]);
  const _validatefechaNacimiento = () => {
    let errors = fechaNacimientoModel.check({
      fechaNacimiento: fechaNacimiento,
    });
    if (errors.fechaNacimiento.hasError) {
      let errorInfo = {
        hasError: errors.fechaNacimiento.hasError,
        error: errors.fechaNacimiento.hasError
          ? errors.fechaNacimiento.errorMessage
          : "",
      };
      setfechaNacimientoError(errorInfo);
    } else {
      setfechaNacimientoError(false);
    }
  };
  // **********************************************************
  // **********************************************************
  const [apellidoMaterno, setapellidoMaterno] = useStateIfMounted("");
  const [apellidoMaternoError, setapellidoMaternoError] =
    useStateIfMounted(false);
  const apellidoMaternoModel = Schema.Model({
    apellidoMaterno: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateapellidoMaterno();
  }, [apellidoMaterno]);
  const _validateapellidoMaterno = () => {
    let errors = apellidoMaternoModel.check({
      apellidoMaterno: apellidoMaterno,
    });
    if (errors.apellidoMaterno.hasError) {
      let errorInfo = {
        hasError: errors.apellidoMaterno.hasError,
        error: errors.apellidoMaterno.hasError
          ? errors.apellidoMaterno.errorMessage
          : "",
      };
      setapellidoMaternoError(errorInfo);
    } else {
      setapellidoMaternoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [email, setemail] = useStateIfMounted("");
  const [emailError, setemailError] = useStateIfMounted(false);
  const emailModel = Schema.Model({
    email: StringType()
      .isEmail("Ingresa un email válido")
      .isRequired("Este campo es obligatorio"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateemail();
  }, [email]);
  const _validateemail = () => {
    let errors = emailModel.check({
      email: email,
    });
    if (errors.email.hasError) {
      let errorInfo = {
        hasError: errors.email.hasError,
        error: errors.email.hasError ? errors.email.errorMessage : "",
      };
      setemailError(errorInfo);
    } else {
      setemailError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [password, setpassword] = useStateIfMounted("");
  const [passwordError, setpasswordError] = useStateIfMounted(false);
  const passwordModel = Schema.Model({
    password: StringType()
      .isRequired("Campo Requerido")
      .minLength(8, "La contraseña debe contener al menos 8 caracteres")
      .addRule((value, data) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.~!#_:;?%/=*+-])(?=.{8,})"
        );
        return strongRegex.test(value);
      }, "Ingresa almenos una letra mayúscula, un número y uno de los siguientes caracteres ! / # % + = ? : * ; . - ~ _"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatepassword();
  }, [password]);
  const _validatepassword = () => {
    let errors = passwordModel.check({
      password: password,
    });
    if (errors.password.hasError) {
      let errorInfo = {
        hasError: errors.password.hasError,
        error: errors.password.hasError ? errors.password.errorMessage : "",
      };
      setpasswordError(errorInfo);
    } else {
      setpasswordError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [repassword, setrepassword] = useStateIfMounted("");
  const [repasswordError, setrepasswordError] = useStateIfMounted(false);
  const repasswordModel = Schema.Model({
    repassword: StringType()
      .isRequired("Campo Requerido")
      .addRule((value, data) => {
        if (password === repassword) {
          return true;
        } else {
          return false;
        }
      }, "Las contraseñas no coinciden"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validaterepassword();
  }, [repassword]);
  const _validaterepassword = () => {
    let errors = repasswordModel.check({
      repassword: repassword,
    });
    if (errors.repassword.hasError) {
      let errorInfo = {
        hasError: errors.repassword.hasError,
        error: errors.repassword.hasError ? errors.repassword.errorMessage : "",
      };
      setrepasswordError(errorInfo);
    } else {
      setrepasswordError(false);
    }
  };
  // **********************************************************
  // **********************************************************
  const armaCelular = (valueCel, country, e, formattedValue) => {
    let stringCel = valueCel.toString();
    let celularNoCode = stringCel.substring(country.dialCode.length);
    setcelularFormat(formattedValue);
    setcelular(valueCel);
    setcelularNoCode(celularNoCode);
    setCodigoCel(country.dialCode);
  }

  const [codigoCel, setCodigoCel] = useStateIfMounted("");
  const [celularNoCode, setcelularNoCode] = useStateIfMounted("");
  const [celularFormat, setcelularFormat] = useStateIfMounted("");
  const [celular, setcelular] = useStateIfMounted("");
  const [celularError, setcelularError] = useStateIfMounted(false);
  const celularModel = Schema.Model({
    celular: StringType().isRequired("Campo Requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecelular();
  }, [celular]);
  const _validatecelular = () => {
    let errors = celularModel.check({
      celular: celular,
    });
    if (errors.celular.hasError) {
      let errorInfo = {
        hasError: errors.celular.hasError,
        error: errors.celular.hasError ? errors.celular.errorMessage : "",
      };
      setcelularError(errorInfo);
    } else {
      setcelularError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [curp, setcurp] = useStateIfMounted("");
  const [curpError, setcurpError] = useStateIfMounted(false);
  const curpModel = Schema.Model({
    curp: StringType().isRequired("Campo Requerido").addRule((value, data) => {
      var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = value.match(re);

      if (!validado)
        //Coincide con el formato general?
        return false;

      //Validar que coincida el dígito verificador
      function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
          lngSuma = 0.0,
          lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
          lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - (lngSuma % 10);
        if (lngDigito == 10) return 0;
        return lngDigito;
      }

      if (validado[2] != digitoVerificador(validado[1])) return false;

      return true; //Validado
    }, "Ingresa un CURP válido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecurp();
  }, [curp]);
  const _validatecurp = () => {
    let errors = curpModel.check({
      curp: curp,
    });
    if (errors.curp.hasError) {
      let errorInfo = {
        hasError: errors.curp.hasError,
        error: errors.curp.hasError ? errors.curp.errorMessage : "",
      };
      setcurpError(errorInfo);
    } else {
      setcurpError(false);
    }
  };
  // **********************************************************
  // **********************************************************
  const _registroPaciente = async () => {
    setLoader(true);
    let request = {
      nombre: nombre,
      usuario: usuario,
      apellido_paterno: apellidoPaterno,
      apellido_materno: apellidoMaterno,
      email: email,
      password: password,
      curp: curp === "" ? null : curp,
      nacionalidad: nacionalidad === "" ? null : nacionalidad,
      telefono: celular,
      codigoTelefono: codigoCel,
      celularFormat: celularNoCode,
      fecha_nacimiento: moment(fechaNacimiento).format("YYYY-MM-D"),
      tipo_usuario: 3,
      genero: genero === "" ? null : genero,
    };

    const { data } = await PacientesAPI.RegistroS1(request);

    if (data.error === true) {
      toaster.push(
        <Message showIcon style={{ maxWidth: "350px" }} type="error" header="Error!" closable duration={4500}>
          {data.errorText}
        </Message>,
        { placement: "topEnd" }
      );
      setError(data.errorText);
      setLoader(false);
      return false;
    }
    if (data.error === false) {

      toaster.push(
        <Message showIcon style={{ maxWidth: "350px" }} type="success" header="Bienvenido!" closable duration={4500}>
          {"Hola paciente"}
        </Message>,
        { placement: "topEnd" }
      );
      localStorage.setItem("uid_user", data.paciente.data.uid);
      localStorage.setItem("tipo_user", "paciente");
      setUser(data.paciente);
      setUserPrincipal(data.paciente);
      setIdDoctor(data.id_paciente);
      setIdUsuario(data.id_usuario);
      window.location.href = '/paciente';
      return true;
    }
  };

  const titleCase = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      let palabra = arr[i];
      arr[i] = palabra.charAt(0).toUpperCase();
      for (var e = 1; e < palabra.length; e++) {
        arr[i] += palabra.charAt(e).toLowerCase();
      }
    }
    const str2 = arr.join(" ");
    return str2;
  }

  const step1Validator = () => {
    // return a boolean
    setError(false);
    if (
      !nombreError &&
      !apellidoPaternoError &&
      !apellidoMaternoError &&
      !emailError &&
      !celularError &&
      nombre !== "" &&
      apellidoPaterno !== "" &&
      apellidoMaterno !== "" &&
      email !== "" &&
      celular !== "" &&
      !fechaNacimientoError &&
      !generoError &&
      !nacionalidadError &&
      !curpError &&
      !usuarioError &&
      genero !== "" &&
      nacionalidad !== "" &&
      curp !== "" &&
      usuario !== "" &&
      fechaNacimiento !== ""
    ) {
      var edad = moment().diff(moment(fechaNacimiento, "YYYY-MM-DD"), "years");
      const isLegal = edad >= 18;

      if (isLegal) {
        if (password === repassword) {
          return _registroPaciente();
        } else {
          setError("Las contraseñas no coinciden");
        }
      } else {
        setError("Debes ser mayor de edad para registrarte o solicita a tu tutor que se registre y agregue tu cuenta");
        return false;
      }
    }

    setError("Verifica la validación del formulario");
    _validatenombre();
    _validateapellidoPaterno();
    _validatefechaNacimiento();
    _validateusuario();
    _validateapellidoMaterno();
    _validateemail();
    _validatecelular();
    _validateemail();
    _validatenacionalidad();
    _validatecurp();
    _validatepassword();
    _validaterepassword();

    return false;
  };


  return (
    <div className="landing">
      <Modal open={modal} onClose={() => setModal(false)}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login__modal">
            <Login></Login>
          </div>
        </Modal.Body>
      </Modal>
      <div className="landing__menu">
        <div className="landing__menu__logo">
          <ReactLogo fill={colors.branding} stroke={colors.branding}></ReactLogo>
          {/* <img src={LOGO}></img> */}
        </div>
        <div className="landing__menu__links">
          <div className="landing__menu__links__item">Acerca de Docca</div>
          <div className="landing__menu__links__item">Aviso de privacidad</div>
          <div className="landing__menu__links__item">Contacto</div>
          <Button size="sm" style={{ backgroundColor: colors.branding, color: "#fff" }} className="buttonBranding" onClick={() => setModal(true)}>
            INICIAR SESIÓN
          </Button>
        </div>
      </div>
      {/* ===== /MENU ===== */}

      {/* ===== HERO ===== */}
      <div className="landing__hero" style={{ height: "auto" }}>

        <Grid fluid>
          <Row style={{ backgroundColor: "white" }}>
            <div className={"container-sections"} style={{ paddingTop: 0 }}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4}>

                </Col>
                <Col xs={16}>
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div>
                        <SubHeadLine bold text="Registro de Paciente"></SubHeadLine>
                      </div>
                      <div>
                        <Breadcrumb style={{ marginBottom: 0 }} separator={"/"}>
                          <Breadcrumb.Item key={Math.random()} active={false} onClick={() => history("/registro")}>
                            Registro
                          </Breadcrumb.Item>
                          <Breadcrumb.Item key={Math.random()} active={true}>
                            Paciente
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      </div>
                    </div>
                    <div style={{ maxHeight: 560, overflowY: "scroll" }}>
                      <div className={"box"}>
                        <SubHeadLine bold text="Datos personales"></SubHeadLine>

                        <Row>
                          <Col xs={8}>
                            <ControlLabelCustom>*Nombre (s)</ControlLabelCustom>
                            <Input
                              size="xs"
                              value={nombre || ''} name={'nombre '}
                              onChange={(val) => setnombre(titleCase(val))}
                              placeholder=""
                              className={nombreError ? "input-error" : "input-no-error"}
                              placeHolder={nombreError ? nombreError.error : ""}
                            ></Input>
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Apellido Paterno</ControlLabelCustom>
                            <Input
                              size="xs"
                              value={apellidoPaterno || ''} name={'apellidoPaterno'}
                              onChange={(val) => setapellidoPaterno(titleCase(val))}
                              className={apellidoPaternoError ? "input-error" : "input-no-error"}
                              placeHolder={apellidoPaternoError ? apellidoPaternoError.error : ""}
                            ></Input>
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Apellido Materno</ControlLabelCustom>
                            <Input
                              size="xs"
                              value={apellidoMaterno || ''} name={'apellidoMaterno'}
                              onChange={(val) => setapellidoMaterno(titleCase(val))}
                              placeHolder={apellidoMaternoError ? apellidoMaternoError.error : ""}
                              className={apellidoMaternoError ? "input-error" : "input-no-error"}
                            ></Input>
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Género</ControlLabelCustom>
                            <InputPicker
                              size={"xs"}
                              placeholder="Selecciona"
                              value={genero || ''} name={'genero'}
                              onChange={(val) => setGenero(val)}
                              block
                              className={generoError ? "input-error" : "input-no-error"}
                              data={generosData}
                              searchable
                            />
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Fecha de nacimiento</ControlLabelCustom>
                            <Whisper placement="autoVerticalEnd" trigger="click"
                              speaker={
                                <Popover full>
                                  <DayPicker
                                    defaultMonth={fechaNacimiento}
                                    modifiersClassNames={{ selected: 'selectedDayPicker', }}
                                    locale={esDate} selected={fechaNacimiento} onSelect={setfechaNacimiento}
                                    mode="single" fromYear={moment().subtract(100, 'years').format("YYYY")} toYear={moment().format('YYYY')} captionLayout="dropdown" />
                                </Popover>
                              }
                            >
                              <InputGroup inside style={{ width: "100%", }} size={"xs"}>
                                <Input style={{ cursor: "pointer" }} className={fechaNacimientoError ? "input-error" : "input-no-error"}
                                  size={"xs"} value={moment(fechaNacimiento).format("D-MMM-YYYY")}
                                  name={'fechaNacimiento'} readOnly />
                                <InputGroup.Addon style={{ cursor: "pointer" }} size={"xs"}>
                                  <IoCalendarOutline />
                                </InputGroup.Addon>
                              </InputGroup>
                            </Whisper>
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Nacionalidad</ControlLabelCustom>
                            <InputPicker
                              placeholder="Selecciona"
                              value={nacionalidad}
                              onChange={(val) => setNacionalidad(val)}
                              block
                              size="xs"
                              data={nacionalidadData}
                              searchable
                              placeHolder={nacionalidadError ? nacionalidadError.error : "Selecciona"}
                              className={nacionalidadError ? "input-error" : "input-no-error"}
                            />
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*CURP</ControlLabelCustom>
                            <Input
                              size={"xs"}
                              value={curp}
                              onChange={(val) => setcurp(val)}
                              placeHolder={curpError ? curpError.error : ""}
                              className={curpError ? "input-error" : "input-no-error"}
                            />
                          </Col>
                          <Col xs={8}>
                            <ControlLabelCustom>*Email</ControlLabelCustom>
                            <Input
                              size="xs"
                              placeHolder={emailError ? emailError.error : ""}
                              value={email || ''} name={'email'}
                              onChange={(val) => setemail(val)}
                              className={emailError ? "input-error" : "input-no-error"}
                            ></Input>
                          </Col>


                        </Row>
                      </div>
                      <div className="box mt-2" >
                        <SubHeadLine bold marginBottom={0} text={"Datos de inicio de sesión"}></SubHeadLine>
                        <Form fluid>
                          <Form.Group>
                            <Row>
                              <Col xs={12}>
                                <ControlLabelCustom>*Username</ControlLabelCustom>
                                <Form.Control
                                  size={"xs"}
                                  value={usuario}
                                  name={"usuario"}
                                  onChange={(val) => setusuario(val)}
                                  className={usuarioError ? "input-error" : "input-no-error"}
                                  placeholder={usuarioError ? usuarioError.error : ""}
                                />
                              </Col>
                              <Col xs={12}>
                                <ControlLabelCustom>*Celular</ControlLabelCustom>
                                <PhoneInput
                                  localization={es}
                                  containerClass="personalPhoneEdit"
                                  enableSearch={true}
                                  disableSearchIcon={true}
                                  countryCodeEditable={false}
                                  country={"mx"}
                                  value={celular || ''} name={'celular'}
                                  onChange={(value, country, e, formattedValue) => armaCelular(value, country, e, formattedValue)}
                                  inputStyle={{ width: "100%", height: 25 }}
                                  className={
                                    celularError ? "input-error" : "input-no-error"
                                  }
                                />
                              </Col>
                              <Col xs={12} >
                                <ControlLabelCustom>*Contraseña</ControlLabelCustom>
                                <Form.Control
                                  type="password"
                                  size={"xs"}
                                  value={password || ''}
                                  name={'password'}
                                  onChange={(val) => setpassword(val)}
                                  className={passwordError ? "input-error" : "input-no-error"}
                                  placeholder={passwordError ? passwordError.error : ""}
                                />
                                <div style={{ marginTop: 10 }}>
                                  <Message type={passwordError ? "error" : "info"} style={{ fontSize: 12 }}>
                                    <p>Requisitos mínimos de la contraseña</p>
                                    <ul>
                                      <li>8 caracteres</li>
                                      <li>Una mayúscula</li>
                                      <li>Una minúscula</li>
                                      <li>Un número</li>
                                      <li>Un caracter especial ! / # % + = ? : * ; . - ~ _</li>
                                    </ul>
                                  </Message>
                                </div>
                              </Col>
                              <Col xs={12} >
                                <ControlLabelCustom>*Confirmar Contraseña</ControlLabelCustom>
                                <Form.Control
                                  size={"xs"}
                                  type="password"
                                  value={repassword || ''}
                                  name={'password'}
                                  onChange={(val) => setrepassword(val)}
                                  className={repasswordError ? "input-error" : "input-no-error"}
                                  placeholder={repasswordError ? repasswordError.error : ""}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Form>
                      </div>
                      <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                        <ButtonAccent size="sm" onClick={() => step1Validator()} text="Crear cuenta" loader={loader ? true : false}></ButtonAccent>
                      </div>
                    </div>
                  </>
                </Col>
              </Row>
            </div>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default Registro;
