import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WebNotFound from "../views/web/notFound";
import Login from "../views/login";
import UserProvider from "../state/UserState";
import RegistroPaciente from "../views/registro/paciente";
import PasswordRecovery from "../views/password-rcovery";
import Error from "../components/Error";
import ErrorPage from "../components/ErrorPage";
import LandingPage from "../views/web/landing";
import TipoRegistroDocca from "../views/registro/selectType";
import Registro from "../views/registro/";

const WebNavigation = (props) => {
  const { navigation } = props;
  // let history = useNavigate();
  const { userPrincipal } = useContext(UserProvider);

  return (
    <>
      <Router basename="/main">
        <Routes>
          <Route exact path="/" element={<LandingPage ></LandingPage>}></Route>

          <Route exact path="/login" element={<LandingPage ></LandingPage>}></Route>

          <Route exact path="/registro" element={<TipoRegistroDocca></TipoRegistroDocca>}></Route>

          <Route exact path="/registro/especialista" element={<Registro ></Registro>}></Route>
          <Route exact path="/registro/paciente" element={<RegistroPaciente ></RegistroPaciente>}></Route>

          <Route exact path="/password-recovery" element={<PasswordRecovery ></PasswordRecovery>}></Route>

          <Route exact path="/error" element={<ErrorPage></ErrorPage>}></Route>

          <Route exact path="/landing" element={<LandingPage></LandingPage>}></Route>

          <Route element={<WebNotFound ></WebNotFound>}></Route>
        </Routes>
      </Router>
    </>
  );
};

export default WebNavigation;
