import axios from "axios";
import api from "../constants/api";

export default class AuthAPI {
  async login(request) {
    return axios.post(api, {
      params: {
        rt: "login",
        app: "auth",
        request,
      },
    });
  }

  async GetUserType(uid) {
    return axios.get(api, {
      params: {
        rt: "get_user_type",
        app: "auth",
        uid,
      },
    });
  }

  async GetUserTypeEmail(email) {
    return axios.get(api, {
      params: {
        rt: "get_user_type_email",
        app: "auth",
        email,
      },
    });
  }

  async ChangePasswordRequest(email) {
    return axios.get(api, {
      params: {
        rt: "change_password_request",
        app: "auth",
        email,
      },
    });
  }

  async ValidarUsernameDisponibilidad(username) {
    return axios.get(api, {
      params: {
        rt: "validar_username_disponibilidad",
        app: "auth",
        username,
      },
    });
  }
}
