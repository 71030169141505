import React, { useContext } from "react";
import color from "../../constants/colors";
import LOGO from "../../assets/images/logo-black.png";
import { useNavigate } from "react-router";
import UserState from "../../state/UserState";
import { Button } from "rsuite";
const ErrorPage = (props) => {
  const history = useNavigate();

  const _reset = () => {
    history("/");
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img style={{ width: 200, marginBottom: 20 }} src={LOGO}></img>
      <span style={{ fontSize: "28px", fontWeight: "bold", color: "#000000" }}>
        {props.text
          ? props.text
          : "Lo sentimos… Ha ocurrido un error, regresa a la pagina anterior"}
      </span>
      <Button
        style={{
          width: 200,
          background: "black",
          color: "white",
          marginTop: 20,
        }}
        onClick={() => _reset()}
      >
        Regresar
      </Button>
    </div>
  );
};

export default ErrorPage;
