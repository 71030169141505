import React from "react";
import ReactDOM from "react-dom";
import AppMain from "./app_main";

ReactDOM.render(
  <React.StrictMode>
    <AppMain />
  </React.StrictMode>,
  document.getElementById("main_root")
);

// if (module.hot) {
//   module.hot.accept(["./app_main"], function(incoming) { console.log("\nHot Module Accepted for Main", incoming) });
// }
