import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "rsuite";
import { ReactComponent as ReactLogo } from "../../assets/images/docca_logo.svg";
import colors from "../../constants/colors";
import Login from "../login";



export const TipoRegistroDocca = (props) => {
    const history = useNavigate();
    const [modal, setModal] = useState(false);

    return (
        <div className="landing">
            <Modal open={modal} onClose={() => setModal(false)}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="login__modal">
                        <Login></Login>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="landing__menu">
                <div className="landing__menu__logo">
                    <ReactLogo fill={colors.branding} stroke={colors.branding}></ReactLogo>
                    {/* <img src={LOGO}></img> */}
                </div>
                <div className="landing__menu__links">
                    <div className="landing__menu__links__item">Acerca de Docca</div>
                    <div className="landing__menu__links__item">Aviso de privacidad</div>
                    <div className="landing__menu__links__item">Contacto</div>
                    <Button size="sm" style={{ backgroundColor: colors.branding, color: "#fff" }} className="buttonBranding" onClick={() => setModal(true)}>
                        INICIAR SESIÓN
                    </Button>
                </div>
            </div>
            {/* ===== /MENU ===== */}

            {/* ===== HERO ===== */}
            <div className="landing__hero" style={{ height: "auto" }}></div>
            <div class="pricing-area ">
                <div class="container">
                    <div class="">
                        <div class="">
                            <div class="section-title">
                                <h2>Elige el tipo de cuenta que quieres crear.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div style={{ display: "flex", marginTop: 20 }}>
                            <div class="pricing-card--2 active">
                                {/* <span class="small-title">Starter</span> */}
                                <h3 class="price">Especialista</h3>
                                <ul class="pricing-list">
                                    <li>Registra tus pacientes</li>
                                    <li>Consulta</li>
                                    <li>Organiza tu agenda</li>
                                </ul>
                                <div class="pricing-btn">
                                    <Button size="md" style={{ backgroundColor: colors.branding, color: "#fff" }} className="buttonBranding" onClick={() => history("/registro/especialista")}>
                                        Registrarme
                                    </Button>
                                    {/* <a onClick={() => history("/registro/especialista")} class="btn ">Registrarme</a> */}
                                </div>
                            </div>
                            <div class="pricing-card--2 active">
                                {/* <span class="small-title">Paciente</span> */}
                                <h3 class="price">Paciente</h3>
                                <ul class="pricing-list">
                                    <li>Agenda con especialistas</li>
                                    <li>Seguimiento a consultas</li>
                                    <li>Controla tus datos médicos</li>
                                </ul>
                                <div class="pricing-btn">
                                    <Button size="md" style={{ backgroundColor: colors.branding, color: "#fff" }} className="buttonBranding" onClick={() => history("/registro/paciente")}>
                                        Registrarme
                                    </Button>
                                    {/* <a onClick={() => history("/registro/paciente")} class="btn ">Registrarme</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TipoRegistroDocca;
