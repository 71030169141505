import React, { useContext, useEffect, useState, useCallback } from "react";
import ButtonAccent from "../../components/ButtonAccent";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const CaptchaButton = ({ onVerifyCaptcha, loader }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const [reCaptcha, setReCaptcha] = useState('');

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log("Execute recaptcha not yet available");
      return;
    }
    (async () => {
      try {
        const token = await executeRecaptcha("signup");
        onVerifyCaptcha(token);
      } catch (error) {
        // console.log(error.response);
      }
    })();

    // Do whatever you want with the token
  }, [executeRecaptcha]);

  return (
    <ButtonAccent
      // onClick={() => _validateFormEmail()}
      onClick={() => handleReCaptchaVerify()}
      text="Enviar"
      loader={loader ? true : false}
      block
    ></ButtonAccent>
  );
  // <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
};
// export default RecaptchaButtonValidation;
export const ReCaptcha = ({ onVerifyCaptcha }) => (
  <GoogleReCaptchaProvider
    // 6LcMa-8bAAAAAJj7igj73qgraK-YHDz-kkeEOuLo
    reCaptchaKey="6LeIGvcbAAAAADwdb6p20UoHkjLCGMj1FLzCqzP9"
  >
    <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
  </GoogleReCaptchaProvider>
);
